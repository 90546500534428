const WhenSection = () => {
    return ( 
        <section className="when-section">
            <div className="when-background-con">
                <h2>WHEN YOU GO ON A TRIP <br/> YOU HELP SPONSOR AN <br/>UPCOMING TECHIE</h2>
            </div>
        </section>
     );
}
 
export default WhenSection;